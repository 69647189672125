<div class="header" [ngStyle]="{'background-color': backgroundColor}">
  <div class="container p-0 m-0">
    <div class="d-flex m-0 justify-content-between flex-wrap">
      <div class="d-flex justify-content-start align-items-center p-0">
        <div class="d-flex align-items-center">
          <div id="sidebarDisplay">
            <img class="menu-admin iconsColor2" (click)="visibleSidebar1 = true" src="../../../assets/images/menu.png">
          </div>
          <a [routerLink]="'/' + lang" class="logo mt-0" data-animation-offset="100"></a>
          <div class="button-secondary button-fit-content button-thin" [routerLink]="'/' + lang + '/booking'">{{ "sidebar.bookingList" | translate }}</div>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center justify-content-end g-8 p-0">
        <p-dropdown id="languageDropdown" [options]="languageList" [(ngModel)]="languageSelected" (onChange)="changeLanguage()">
          <ng-template pTemplate="selectedItem">
            <div class="d-flex align-items-center justify-content-start" *ngIf="languageSelected">
                <div>{{languageSelected.name}}</div>
            </div>
        </ng-template>
        <ng-template let-language pTemplate="item">
          <div class="d-flex align-items-center justify-content-start g-8">
              <img src="../../../assets/images/{{language?.name.toLocaleLowerCase()}}_flag.png" />
              <div>{{language.name}}</div>
          </div>
        </ng-template>
        </p-dropdown>
        <div class="userProfile" (click)="userProfileSubMenu = !userProfileSubMenu" #userProfileSubMenuModal>
          <ng-container *ngIf="user">
            <img class="rounded-image" src="{{userPhoto}}">
            <div class="userProfileInfo">
              <div>{{ lead }}</div>
              <div>{{ user?.email }}</div>
            </div>
            <div class="userProfileSubMenu" *ngIf="userProfileSubMenu" id="userProfileSubMenuModal">
              <div (click)="userProfileSubMenu= false; onRouterLink('user-settings')"><img src="../../../assets/images/settings_icon.png"> Settings</div>
              <div class="lineuserProfileSubMenu">
              </div>
              <div (click)="userProfileSubMenu= false; logout()"><img src="../../../assets/images/log_out_icon.png"> {{ "header.logout" | translate }}</div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!environment" style="text-align: center; font-size: 24px; color: red;" [ngStyle]="{'background-color': backgroundColor}">THIS IS A TEST ENVIRONMENT - GO TO FLIGHTS.VUELANDIA.COM</div>

<app-breadcrumb *ngIf="hideBreadcrumb == 'false'" page="{{page}}"></app-breadcrumb>

<p-sidebar 
  [(visible)]="visibleSidebar1" 
  [appendTo]="'body'" 
  [blockScroll]="true" 
  [showCloseIcon]="false">
  <div id="sidebar-header">
    <img src="../../../assets/images/close_sidebar.png" (click)="closeSidebar()" class="iconsColor2 closeBoton">
    <ul class="list-unstyled components mb-5">
      <li *ngFor="let item of sidebarItemList">
        <ng-container *ngIf="item.route; else withoutRoute">
          <a *ngIf="!item.adminRequired || (item.adminRequired && isAdmin)"
            class="sidebar-item"
            (click)="visibleSidebar1 = false"
            [routerLink]="'/' + lang + '/' + item.route"
            [routerLinkActive]="'menuSelected'"
            [routerLinkActiveOptions]="{ exact: true }">
            <img *ngIf="item.icon !== undefined" [src]="'../../../assets/images/' + item.icon" />
            <span>{{ 'sidebar.' + item.name | translate }}</span>
          </a>
        </ng-container>
        <ng-template #withoutRoute>
          <a *ngIf="!item.adminRequired || (item.adminRequired && isAdmin)" (click)="item.expanded = !item.expanded" [ngClass]="{'subItemExpanded': item.expanded}"
            class="sidebar-item with-sub-item"
            [ngClass]="{ 'menuSelected': hasActiveSubItem(item.subElementList) }">
            <img *ngIf="item.icon !== undefined" [src]="'../../../assets/images/' + item.icon" />
            <span class="d-flex flex-row justify-content-between w-100">{{ 'sidebar.' + item.name | translate }}<i class="fa fa-chevron-down d-flex align-items-center"></i></span>
          </a>
          <div *ngIf="item.subElementList !== undefined && isAdmin && item.expanded" class="subElementList">
            <div class="lineSubItem"></div>
            <ul class="sidebar-sub-item">
              <li *ngFor="let subItem of item.subElementList"
              [ngClass]="{ 'menuSelected': this.router.isActive('/' + this.lang + '/' + subItem.route, true)}">
                <a *ngIf="!subItem.adminRequired || (subItem.adminRequired && isAdmin)"
                  (click)="visibleSidebar1 = false"
                  [routerLink]="'/' + lang + '/' + subItem.route"
                  [routerLinkActive]="'menuSelected'"
                  [routerLinkActiveOptions]="{ exact: true }">
                  
                  <span>{{ 'sidebar.' + subItem.name | translate }}</span>
                </a>
              </li>
            </ul>
          </div>

        </ng-template>
      </li>
    </ul>
  </div>
</p-sidebar>