export const environment = {
  production: true,
  version: "2.05",
  apiRoot: "https://apiflights.vuelandia.com",
  apiUrl: "https://apiflights.vuelandia.com/api/v2",
  originUrl: "https://flights.vuelandia.com/",
  auth: {
    domain: 'vuelandia.eu.auth0.com',
    clientId: '32redMgELWuQ9bek3rBJgQ3j96wyRKU5',
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: 'localstorage' as const,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: "https://apiflights.vuelandia.com"
    },
    errorPath: '/error',
    httpInterceptor: {
      allowedList:[
        'https://apiflights.vuelandia.com/*',
      ]
    }
  },
  apiVenturis:{
    url: "https://portal.veturis.com/loginextService.php",
    user: "vuelandia",
    pass: "QFgDzQ2x8a"
  },
  apiflexible: "https://www.flexibleautos.nl/?deeplink=",
  groupSameFlights: true,
  requote: false
};
