import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { Style, UserDto } from "../models/low-fare-search.model";
import { Language } from "../models/extra-service.model";
import { MarkupAgencyDto } from "../models/markup-agency.model";

@Injectable({
  providedIn: "root",
})
export class UserService extends BaseService {
  headers = new HttpHeaders({ "Content-Type": "application/json" });
  manualLanguage = false;
  usuario: UserDto;

  constructor(public http: HttpClient) {
    super();
  }

  //Language
  getAllLanguages(): Observable<Array<Language>> {
    return this.http.get<Array<Language>>(this.apiUrl + "/content/Languages");
  }

  // changePassword(changePassword: ChangePassword): Observable<any> {
  //   return this.http.post<any>(this.openIdConnectSettings.authority + "/Account/ResetPasswordFlights", changePassword, {
  //     headers: this.headers,
  //   });
  // }

  getMakupAgency(): Observable<MarkupAgencyDto> {
    return this.http.get<MarkupAgencyDto>(this.apiUrl + "/Markup/MarkupAgency");
  }
  updateMarkupAgency(markupAgencyDto: MarkupAgencyDto): Observable<any> {
    return this.http.post<any>(this.apiUrl + "/Markup/MarkupAgencyUpdate", markupAgencyDto);
  }

  getLead(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/User/UserInfo");
  }

  refreshUser(): Observable<any> {
    return this.http.get<any>(this.apiUrl + "/User/RefreshUser/");
  }
  getUserPhoto(): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'image/png' });
    return this.http.get(this.apiUrl + '/User/getUserPhoto', { responseType: 'blob', headers: headers });
  }
  getUsuario(refresh: boolean = false): Observable<UserDto> {
    return this.http.get<UserDto>(this.apiUrl + "/Account/User/" + refresh);
  }

  setStyle() {
    this.getUsuario().subscribe(resp => {
      this.usuario = resp;
      if (sessionStorage.hasOwnProperty("windowReturn")) {
        let urlT1 = sessionStorage.getItem("windowReturn").split("/");
        let urlT2 = window.location.origin.split("/");
        if (
          urlT1[urlT1.length - 1] == "en" ||
          urlT1[urlT1.length - 1] == "es" ||
          urlT1[urlT1.length - 1] == "nl" ||
          urlT1[urlT1.length - 1] == ""
        )
          urlT1.pop();
        let same = urlT1.length === urlT2.length && urlT1.every((value, index) => value === urlT2[index]);
        //console.log('TEST', sessionStorage.getItem('windowReturn'), window.location.origin, same, document.referrer);

        urlT1 = sessionStorage.getItem("windowReturn").split("/");
        urlT2 = document.referrer.split("/");
        if (
          urlT1[urlT1.length - 1] == "en" ||
          urlT1[urlT1.length - 1] == "es" ||
          urlT1[urlT1.length - 1] == "nl" ||
          urlT1[urlT1.length - 1] == ""
        )
          urlT1.pop();
        let same2 = urlT1.length === urlT2.length && urlT1.every((value, index) => value === urlT2[index]);

        urlT1 = window.location.origin.split("/");
        urlT2 = document.referrer.split("/");
        if (
          urlT1[urlT1.length - 1] == "en" ||
          urlT1[urlT1.length - 1] == "es" ||
          urlT1[urlT1.length - 1] == "nl" ||
          urlT1[urlT1.length - 1] == ""
        )
          urlT1.pop();
        let same3 = urlT1.length === urlT2.length && urlT1.every((value, index) => value === urlT2[index]);

        if (same || same2 || same3 || document.referrer == "" || document.referrer == document.baseURI) {
          //console.log('TEST REMOVE windowReturn', sessionStorage.getItem('windowReturn'));
          sessionStorage.removeItem("windowReturn");
        }
      }

      if (this.usuario != null && this.usuario.style != null) {
        document.documentElement.style.setProperty("--primary-color", "#37FF00");
        this.setDefaultStyle(this.usuario.style, this.usuario.agencyLogo);
      } else {
        this.setDefaultStyle(null, null);
      }
    });
  }

  changeLogo(logo: string) {
    if (logo) document.documentElement.style.setProperty("--image-logo", 'url("' + logo + '")');
    else document.documentElement.style.setProperty("--image-logo", "url('./assets/images/logo.png')");
  }

  changeColor(
    primaryColor: string,
    secondaryColor: string,
    thirdColor: string,
    imageColor: string,
    fontPrimaryColor: string,
    fontSecondaryColor: string,
    fontPlaceholderColor: string,
    fontDisabledColor: string,
    fontLinkColor: string
  ) {
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    if (primaryColor != null) {
      let rgb = this.hexToRgb(primaryColor);
      var rgbaPrimaryColor = "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ")";
      if (rgb != null)
        document.documentElement.style.setProperty(
          "--primary-color-rgba",
          "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ", 0.8)"
        );


      if (this.lightOrDark(rgbaPrimaryColor) == 'dark') {
        document.documentElement.style.setProperty("--search-color", '#fff');
        let color = new Color(255, 255, 255);
        let solver = new Solver(color);
        let result = solver.solve();
        document.documentElement.style.setProperty(
          "--search-image-color",
          result.filter
        );
      } else {
        document.documentElement.style.setProperty("--search-color", '#000');
        let rgb = this.hexToRgb(primaryColor);
        let color = new Color(rgb[0], rgb[1], rgb[2]);
        let solver = new Solver(color);
        let result = solver.solve();
        document.documentElement.style.setProperty(
          "--search-image-color",
          result.filter
        );
      }

    }
    document.documentElement.style.setProperty("--secondary-color", secondaryColor);
    if (secondaryColor != null) {
      let rgb = this.hexToRgb(secondaryColor);
      if (rgb != null)
        document.documentElement.style.setProperty(
          "--secondary-color-rgba",
          "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ", 0.6)"
        );
      document.documentElement.style.setProperty(
        "--third-color-rgba",
        "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ", 0.3)"
      );

    }
    //to delete when color 3 (style 1) in backoffice is changed to #F4F7F9)
    if (primaryColor == thirdColor) thirdColor = "#F4F7F9";
    //end to delete
    document.documentElement.style.setProperty("--third-color", thirdColor);

    //image
    if (imageColor != null) {
      let rgb = this.hexToRgb(imageColor);
      let color = new Color(rgb[0], rgb[1], rgb[2]);
      let solver = new Solver(color);
      let result = solver.solve();
      document.documentElement.style.setProperty("--image-color", result.filter);
    } else document.documentElement.style.setProperty("--image-color", imageColor);

    if (fontPrimaryColor != null) {
      let rgb = this.hexToRgb(fontPrimaryColor);
      let color = new Color(rgb[0], rgb[1], rgb[2]);
      let solver = new Solver(color);
      let result = solver.solve();
      document.documentElement.style.setProperty("--image-color2", result.filter);
    } else document.documentElement.style.setProperty("--image-color2", fontPrimaryColor);

    if (primaryColor != null) {
      let rgb = this.hexToRgb(primaryColor);
      let color = new Color(rgb[0], rgb[1], rgb[2]);
      let solver = new Solver(color);
      let result = solver.solve();
      document.documentElement.style.setProperty("--image-primary-color", result.filter);
    } else document.documentElement.style.setProperty("--image-primary-color", primaryColor);

    if (fontDisabledColor != null) {
      let rgb = this.hexToRgb(fontDisabledColor);
      let color = new Color(rgb[0], rgb[1], rgb[2]);
      let solver = new Solver(color);
      let result = solver.solve();
      document.documentElement.style.setProperty("--image-disabled-color", result.filter);
    } else document.documentElement.style.setProperty("--image-disabled-color", fontDisabledColor);

    // Font color
    document.documentElement.style.setProperty("--font-primary-color", fontPrimaryColor);
    document.documentElement.style.setProperty("--font-secondary-color", fontSecondaryColor);
    document.documentElement.style.setProperty("--font-placeholder-color", fontPlaceholderColor);
    document.documentElement.style.setProperty("--font-disabled-color", fontDisabledColor);
    document.documentElement.style.setProperty("--font-links-color", fontLinkColor);

    /*if(checkbox1ImageColor != null){
        let rgb = this.hexToRgb(checkbox1ImageColor);
        let color = new Color(rgb[0], rgb[1], rgb[2]);
        let solver = new Solver(color);
        let result = solver.solve();
        document.documentElement.style.setProperty('--checkbox1-image-color', result.filter);
      } else document.documentElement.style.setProperty('--checkbox1-image-color', checkbox1ImageColor);

      if(checkbox2ImageColor != null){
        let rgb = this.hexToRgb(checkbox2ImageColor);
        let color = new Color(rgb[0], rgb[1], rgb[2]);
        let solver = new Solver(color);
        let result = solver.solve();
        document.documentElement.style.setProperty('--checkbox2-image-color', result.filter);
      } else document.documentElement.style.setProperty('--checkbox2-image-color', checkbox2ImageColor);*/
  }
  lightOrDark(color) {
    var r, g, b;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'
      )
      );

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    var hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {

      return 'light';
    }
    else {

      return 'dark';
    }
  }
  setDefaultStyle(style: Style, logo: string) {
    //this.changeColor('#2d4e89', '#f47025', '#2d4e89', '#f47025', '#ffffff', '#243249');
    //this.changeColor('#3d5d76', '#e7884f', '#3d5d76', '#e7884f', '#ffffff', '#243249');
    this.changeLogo(logo);
    this.changeColor(
      style?.color1 ? style.color1 : "#3d5d76",
      style?.color2 ? style.color2 : "#D77031",
      style?.color3 ? style.color3 : "#F4F7F9",
      style?.color4 ? style.color4 : "#D77031",
      style?.fontPrimaryColor ? style.fontPrimaryColor : "#444444",
      style?.fontSecondaryColor ? style.fontSecondaryColor : "#747474",
      style?.fontPlaceholderColor ? style.fontPlaceholderColor : "#A1A9B8",
      style?.fontDisabledColor ? style.fontDisabledColor : "#B9C0D4",
      style?.fontLinksColor ? style.fontLinksColor : "#CA7540"
    );
  }

  /*setStyle1(){
    //this.changeColor('#1a1844', '#b07819', '#1a1844', '#b07819', '#b07819', '#243249');
    this.changeColor('#1a1844', '#b07819', '#1a1844', '#b07819');
  }*/
  hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
  }
}

class Color {
  r: any;
  g: any;
  b: any;

  constructor(r, g, b) {
    this.set(r, g, b);
  }

  toString() {
    return `rgb(${Math.round(this.r)}, ${Math.round(this.g)}, ${Math.round(this.b)})`;
  }

  set(r, g, b) {
    this.r = this.clamp(r);
    this.g = this.clamp(g);
    this.b = this.clamp(b);
  }

  hueRotate(angle = 0) {
    angle = (angle / 180) * Math.PI;
    let sin = Math.sin(angle);
    let cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ]);
  }

  grayscale(value = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ]);
  }

  sepia(value = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ]);
  }

  saturate(value = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ]);
  }

  multiply(matrix) {
    let newR = this.clamp(this.r * matrix[0] + this.g * matrix[1] + this.b * matrix[2]);
    let newG = this.clamp(this.r * matrix[3] + this.g * matrix[4] + this.b * matrix[5]);
    let newB = this.clamp(this.r * matrix[6] + this.g * matrix[7] + this.b * matrix[8]);
    this.r = newR;
    this.g = newG;
    this.b = newB;
  }

  brightness(value = 1) {
    this.linear(value);
  }

  contrast(value = 1) {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  linear(slope = 1, intercept = 0) {
    this.r = this.clamp(this.r * slope + intercept * 255);
    this.g = this.clamp(this.g * slope + intercept * 255);
    this.b = this.clamp(this.b * slope + intercept * 255);
  }

  invert(value = 1) {
    this.r = this.clamp((value + (this.r / 255) * (1 - 2 * value)) * 255);
    this.g = this.clamp((value + (this.g / 255) * (1 - 2 * value)) * 255);
    this.b = this.clamp((value + (this.b / 255) * (1 - 2 * value)) * 255);
  }

  hsl() {
    let r = this.r / 255;
    let g = this.g / 255;
    let b = this.b / 255;
    let max = Math.max(r, g, b);
    let min = Math.min(r, g, b);
    let h: number,
      s: number,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return {
      h: h * 100,
      s: s * 100,
      l: l * 100,
    };
  }

  clamp(value) {
    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }
    return value;
  }
}

class Solver {
  target: any;
  targetHSL: any;
  reusedColor: any;

  constructor(target) {
    this.target = target;
    this.targetHSL = target.hsl();
    this.reusedColor = new Color(0, 0, 0); // Object pool
  }

  solve() {
    let result = this.solveNarrow(this.solveWide());
    return {
      values: result.values,
      loss: result.loss,
      filter: this.css(result.values),
    };
  }

  solveWide() {
    const A = 5;
    const c = 15;
    const a = [60, 180, 18000, 600, 1.2, 1.2];

    let best = { loss: Infinity };
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      let initial = [50, 20, 3750, 50, 100, 100];
      let result = this.spsa(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best = result;
      }
    }
    return best;
  }

  solveNarrow(wide) {
    const A = wide.loss;
    const c = 2;
    const A1 = A + 1;
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];
    return this.spsa(A, a, c, wide.values, 500);
  }

  spsa(A, a, c, values, iters) {
    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best = null;
    let bestLoss = Infinity;
    let deltas = new Array(6);
    let highArgs = new Array(6);
    let lowArgs = new Array(6);

    for (let k = 0; k < iters; k++) {
      let ck = c / Math.pow(k + 1, gamma);
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = values[i] + ck * deltas[i];
        lowArgs[i] = values[i] - ck * deltas[i];
      }

      let lossDiff = this.loss(highArgs) - this.loss(lowArgs);
      for (let i = 0; i < 6; i++) {
        let g = (lossDiff / (2 * ck)) * deltas[i];
        let ak = a[i] / Math.pow(A + k + 1, alpha);
        values[i] = fix(values[i] - ak * g, i);
      }

      let loss = this.loss(values);
      if (loss < bestLoss) {
        best = values.slice(0);
        bestLoss = loss;
      }
    }
    return { values: best, loss: bestLoss };

    function fix(value, idx) {
      let max = 100;
      if (idx === 2 /* saturate */) {
        max = 7500;
      } else if (idx === 4 /* brightness */ || idx === 5 /* contrast */) {
        max = 200;
      }

      if (idx === 3 /* hue-rotate */) {
        if (value > max) {
          value = value % max;
        } else if (value < 0) {
          value = max + (value % max);
        }
      } else if (value < 0) {
        value = 0;
      } else if (value > max) {
        value = max;
      }
      return value;
    }
  }

  loss(filters) {
    // Argument is array of percentages.
    let color = this.reusedColor;
    color.set(0, 0, 0);

    color.invert(filters[0] / 100);
    color.sepia(filters[1] / 100);
    color.saturate(filters[2] / 100);
    color.hueRotate(filters[3] * 3.6);
    color.brightness(filters[4] / 100);
    color.contrast(filters[5] / 100);

    let colorHSL = color.hsl();
    return (
      Math.abs(color.r - this.target.r) +
      Math.abs(color.g - this.target.g) +
      Math.abs(color.b - this.target.b) +
      Math.abs(colorHSL.h - this.targetHSL.h) +
      Math.abs(colorHSL.s - this.targetHSL.s) +
      Math.abs(colorHSL.l - this.targetHSL.l)
    );
  }

  css(filters) {
    function fmt(idx, multiplier = 1) {
      return Math.round(filters[idx] * multiplier);
    }

    //return `filter: invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(4)}%) contrast(${fmt(5)}%);`;
    return `invert(${fmt(0)}%) sepia(${fmt(1)}%) saturate(${fmt(2)}%) hue-rotate(${fmt(3, 3.6)}deg) brightness(${fmt(
      4
    )}%) contrast(${fmt(5)}%)`;
  }
}
